<template>
    <div class="aplayz_container">
        <div class="aplayz_container_bg"></div>
        <coupon-guide v-if="isOpenGuide" @close-couponguide="isOpenGuide = false" />
        <header class="hanwha_header">
            <nav class="header_nav">
                <ul class="flex_space_between align_center">
                    <li>
                        <h1 class="header_left_title">PLAYLIST for prairie</h1>
                    </li>
                    <li>
                        <img
                            class="aplayz_hanwha_logo"
                            src="/media/img/hanwhaprairie/hanwha_aplayzlogo.png"
                            alt="어플레이지x한화"
                        />
                    </li>
                </ul>
            </nav>
        </header>
        <main class="aplayz_main">
            <ul class="music_list">
                <li class="music_item" v-for="item in musicList" :key="item.musicId">
                    <img class="music_album_img" :src="item.musicImg" :alt="item.musicName" />
                    <div class="music_info">
                        <span class="music_name">{{ item.musicName }}</span>
                        <span class="music_artist">{{ item.artist }}</span>
                    </div>
                </li>
            </ul>
        </main>
        <footer class="aplayz_footer">
            <div class="aplayz_footer_info">
                <h2 class="aplayz_info_title">내 공간에 어울리는 음악을 더 듣고 싶다면?</h2>
                <h3 class="aplayz_info_title2">지금 바로 어플레이즈를 이용해보세요!</h3>
                <div class="coupon_box">
                    <h2 class="coupon_info_text">
                        1개월 무료 이용권 코드 : <strong class="coupon_info_text bold">{{ couponCode }}</strong>
                    </h2>
                    <ul class="coupon_info_box">
                        <li>
                            <button class="coupon_info_item align_center" @click="copyCode">
                                <img class="content_copy" src="/media/img/hanwhaprairie/content_copy.png" alt="코드복사" />
                                코드 복사
                            </button>
                        </li>
                        <li>
                            <button class="coupon_info_item align_center" @click="openGuide">
                                사용 방법
                                <img class="arrow_forward" src="/media/img/curation/arrow_forward_white.png" alt="사용 방법" />
                            </button>
                        </li>
                    </ul>
                </div>
                <button class="app_donwonload" @click="appdownload">앱 다운로드 바로가기</button>
                <!-- The actual snackbar -->
                <div id="snackbar">
                    <img class="check_circle" src="/media/img/hanwhaprairie/check_circle.png" alt="성공" />
                    코드복사가 완료되었습니다.
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
  name: 'HanwhaPrairie',
  components: {
    'coupon-guide': () => import(`@/components/hanwhaPrairie/CouponGuide.vue`)
  },
  data () {
    return {
      musicList: [
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum1.png',
          musicId: 'musicId1',
          musicName: 'Between The lakes',
          artist: 'David Tolk'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum2.png',
          musicId: 'musicId2',
          musicName: 'Unborn Child',
          artist: 'Michael Allen Harrison'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum3.png',
          musicId: 'musicId3',
          musicName: 'Night Prayer',
          artist: 'Jim Brickman'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum4.png',
          musicId: 'musicId4',
          musicName: 'Heart Seeker',
          artist: 'John Albert Thomas'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum5.png',
          musicId: 'musicId5',
          musicName: 'In Reverence',
          artist: 'Phillip Wesly'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum6.png',
          musicId: 'musicId6',
          musicName: 'Pary',
          artist: 'David Tolk'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum7.png',
          musicId: 'musicId7',
          musicName: 'A Silent Tear',
          artist: 'Joe Yamada'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum8.png',
          musicId: 'musicId8',
          musicName: 'Home',
          artist: 'David Tolk'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum9.png',
          musicId: 'musicId9',
          musicName: 'Echo Lake',
          artist: 'Tim Janis'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum10.png',
          musicId: 'musicId10',
          musicName: 'In Perfect Silence',
          artist: 'David Tolk'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum11.png',
          musicId: 'musicId11',
          musicName: 'Imagination',
          artist: 'Joe Yamada'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum12.png',
          musicId: 'musicId12',
          musicName: 'Do You',
          artist: 'Yiruma'
        },
        {
          musicImg: '/media/img/hanwhaprairie/musicAlbum13.png',
          musicId: 'musicId13',
          musicName: 'Home Sweet Home',
          artist: 'Kostia Konstantinoff'
        }
      ],
      couponCode: 'prairie',
      isOpenGuide: false,
      isActiveSnackBar: false
    };
  },

  created () {},

  mounted () {
    const app = document.querySelector('html');
    app.style.overscrollBehaviorY = 'initial';
    app.style.height = 'auto';
  },

  methods: {
    copyCode () {
      if (!this.isActiveSnackBar) {
        navigator.clipboard.writeText(this.couponCode).then(() => {
          const x = document.getElementById('snackbar');
          // Add the "show" class to DIV
          x.className = 'show';
          this.isActiveSnackBar = true;
          setTimeout(() => {
            x.className = x.className.replace('show', '');
            this.isActiveSnackBar = false;
          }, 2000);
        });
      }
    },
    appdownload () {
      const agent = navigator.userAgent.toLowerCase();

      if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1 || agent.indexOf('ipod') > -1) {
        window.open('https://apps.apple.com/kr/app/aplayz/id6446978790', 'blank');
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.mobileaos.aplayz', 'blank');
      }
    },
    openGuide () {
      this.isOpenGuide = true;
    }
  },

  destroyed () {
    const app = document.querySelector('html');
    app.style.overscrollBehaviorY = 'contain';
    app.style.height = '100vh';
  }
};
</script>
<style src="@/assets/css/reset.css"></style>
<style src="@/assets/css/hanwhaprairie/hanwhafont.css"></style>
<style scoped src="@/assets/css/hanwhaprairie/hanwhaprairie.css"></style>
